import Head from 'next/head'
import Footer from "./footer"
import Header from "./header"
import {FingerprintContext} from "./context";
import React, {useEffect, useState} from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

let fpPromise = null;

if (typeof window !== 'undefined') {
    fpPromise = FingerprintJS.load();
}


export default function Layout({
                                   children,
                                   title = process.env.NEXT_PUBLIC_WEBSITE_NAME,
                                   description = 'Extens your tax return through October 15th',
                                   preHeader = <></>,
                                   containerClassName = ''
                               }) {

    const [fingerprint, setFingerprint] = useState(null);

    useEffect(() => {
        if (typeof window !== 'undefined' && fpPromise != null) {
            ;(async () => {
                // Get the visitor identifier when you need it.
                const fp = await fpPromise
                const result = await fp.get()

                // This is the visitor identifier:
                const visitorId = result.visitorId
                console.log("visitorId: " + visitorId);
                setFingerprint(visitorId);
            })()
        }
    }, []);

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"/>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5"/>
                <meta name="description" content={description}/>
                <meta name="author" content={process.env.NEXT_PUBLIC_WEBSITE_NAME}/>
                <meta name="norton-safeweb-site-verification" 
                content="xag5wjtb7hb010yj610utegtmoc34np0yxgjm1h9dze4zqyyhtmvm8cg1j3z51oe3hyp8-srdxidr573it3-eejpnjx2zq2sr4agj45ns1xjquxrvc89608bwrhp6hmv" />
            </Head>

            <header className="home-header" id="top">
                <Header/>
                <FingerprintContext.Provider value={{str: fingerprint}}>
                    <div
                        className={containerClassName && containerClassName !== "" ? containerClassName : 'inner-container'}>
                        <div className="container">
                            {preHeader}
                        </div>
                    </div>

                    {children}
                </FingerprintContext.Provider>

            </header>
            <Footer/>
        </>
    )
}
